import * as React from "react"
// import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { Container, Row, Col, Form } from 'react-bootstrap';

const IndexPage = () => (
  <Layout>
    <Seo title="Plan Your Perfect Day /////" />
    <Container className="text-center">
      <StaticImage
        src="../images/logo-traced-2.jpg"
        loading="eager"
        width={250}
        quality={75}
        formats={["auto", "webp", "avif"]}
        alt="Events by Jordi Logo"
        className="logo"
      />
    </Container>
    
    <div className="dark-brown mt-4">
      <Container fluid>
        <Row>
          <Col xs={{ order: 'last' }} lg={  { order: 'first'}} className="d-flex px-5 justify-content-center align-items-center flex-column intro">
            <h1 className="display-4 my-3">Your <em>perfect</em> day.</h1>
            <p className="lead">“Working with Jordi was an amazing experience. She took care of every detail, took all of our preferences into consideration, and truly went above and beyond to make our special day that much more special. She was always readily available, informative, and a complete joy to be around. Thanks for making all of the planning and the day of wedding hassle-free!”
 </p>
 <p className="lead">- Josh and Patti Baker</p>
            {/* <p className="lead">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse vel felis vehicula, commodo enim sed, tempor turpis. Vivamus cursus venenatis iaculis. Phasellus nisi orci, malesuada et elit nec, sagittis vehicula velit. Cras tortor urna, malesuada ac nunc et, mollis malesuada dolor. Quisque aliquet sapien ac leo fermentum, a rhoncus dolor fringilla. 
            </p> */}
            {/* <p>
              <a href="#contact" className="btn btn-outline-light lead btn-lg my-2">Book <em>your</em> event with Jordi</a>
            </p> */}
          </Col>
          <Col lg={7} >
            <p className="py-3 m-0">
            <StaticImage
              src="../images/couple-dancing.jpg"
              loading="eager"
              quality={75}
              formats={["auto", "webp", "avif"]}
              alt="Wedding Couple strolling around street"
            />
            </p>
          </Col>
        </Row>
      </Container>
    </div>
      <Container className="mt-4">
        <Row>
          <Col xs={{ order: 'last' }} lg={  { order: 'first'}} className="d-flex pe-lg-4  flex-column">
            <h2>Wedding Planning</h2>
            {/* <h3 className="text-start"></h3> */}
            <p className="lead"><strong>Day-Of-Wedding Planning</strong><br />
            

On the day of coordination, I’m involved in taking care of all the logistical details of the wedding on the day of the wedding itself. For those who choose day-of-coordinators, this type of wedding planner helps to make your wedding day run smoothly but does not help with the rest of the planning beforehand.  
            </p>
            <p className="lead"><strong>Full-Service Wedding Planners</strong><br />
            

Full-Service involves everything included in Day of Coordination plus a lot more, such as managing budget, vendors, venues, and all the nitty-gritty details that go into planning a wedding.


            </p>
            <p className="lead">
            What DON’T I do? I work very hard during the wedding planning process to make your life easy and stress-free so that you can really enjoy your time of being engaged.  Full services wedding planners do as much and as little as you want them to. If you want to be very involved, or very relaxed, I’m there to help in whatever capacity you need. 
            </p>
          </Col>
          <Col>
            <p className="py-3 m-0">
            <StaticImage
              src="../images/wedding-table-setting.jpg"
              quality={75}
              formats={["auto", "webp", "avif"]}
              alt="Wedding Table Setting"
            />
            </p>
          </Col>
        </Row>
      </Container>

      <Container>
        <hr />
        <Row>
          
          <Col lg={6} >
            <p className="py-3 m-0">
            <StaticImage
              src="../images/event-table-setting.jpg"
              quality={75}
              formats={["auto", "webp", "avif"]}
              alt="Event Table Setting"
            />
            </p>
          </Col>
          <Col  className="d-flex pe-lg-4  flex-column">
            <h2>Other Events!</h2>
            <p className="lead">
            Party planning is working with you to create your vision and make sure everything goes smoothly. At the end of the day, parties are all about bringing people together and having fun. Keeping this in mind throughout the process and using it as a compass as we make your major party planning decisions is a foolproof way to throw one of the best parties of all time.            </p>
          </Col>
        </Row>
        <hr />
      </Container>
      <Container className="">
        <h2 className="text-center">What People Are Saying About Jordi</h2>
        <blockquote className="singleCol lead">
          <p>
          “Working with Jordi has been nothing but a blessing!!! I highly recommend you to hire her as your wedding planner, not only is she professional but she always has a genuine spirit, and goes above and beyond to make sure your wedding is the wedding of your dreams!
          </p>
          <p>
            Me and my husband are so blessed that God placed Jordi in our lives to help with our special day!”
          </p>
          <cite>– A. Archibold</cite>
        </blockquote>
        <hr />
      </Container>
      
      <Container className="singleCol">
        <h2 className="text-center">Contact Me</h2>
        <Row>
          <Col lg={5}>
            <p className="lead">Phone: (904) 699-9460</p>
            <p className="lead">Email: <a href="mailto:eventsbyjordi@gmail.com">eventsbyjordi@gmail.com</a></p>
            
          </Col>
          <Col lg={7}>
          <Form 
                        name="contact"
                        method="post"
                        // action="/contact-success/"
                        data-netlify="true"
                        data-netlify-honeypot="bot-field"
                        onSubmit="submit"
                    >
                    {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                    <input type="hidden" name="form-name" value="contact" />
                    <p hidden>
                    <label>
                        Don’t fill this out: <input name="bot-field" />
                    </label>
                    </p>
                     


                        <Row>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label for="firstName">First Name</Form.Label>
                                    <Form.Control 
                                        type="text" name="first-name" id="firstName"
                                     />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                            <Form.Group>
                                    <Form.Label for="lastName">Last Name</Form.Label>
                                    <Form.Control 
                                       type="text" name="last-name"
                                       id="lastName"
                                     />
                            </Form.Group>
                            </Col>
                        </Row>


                            
                        <Form.Group>
                            <Form.Label for="email">Email address</Form.Label>
                            <Form.Control 
                                required
                                type="email" 
                                name="email" 
                                id="email"
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label for="phone">Phone</Form.Label>
                            <Form.Control 
                                required
                                type="text" 
                                name="phone" 
                                id="phone"
                            />
                        </Form.Group>
                        
                        <Form.Group>
                            <Form.Label for="help">How can Jordi help you?</Form.Label>
                            <Form.Control 
                                as="textarea" 
                                name="message"
                                rows="3"
                                id="help"
                             />
                        </Form.Group>
                        <button type="submit" className="btn btn-outline-dark">Submit</button>
                        </Form>
          </Col>
        </Row>
      </Container>

    
    
      
  </Layout>
)



/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default IndexPage
